import { FastPaceCompanyDetailsComponent } from './fastpace-company-details/fastpace.company.details.component';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { SharedModule } from '../shared.module';
import { FastPaceCompanyOnGuardComponent } from './company-settings/onguard/fastpace.company.onguard.component';
import { FastPaceCompanyCCureComponent } from './company-settings/ccure/fastpace.company.ccure.component';
import { FastPaceCompanyNetboxComponent } from './company-settings/netbox/fastpace.company.netbox.component';
import { FastPaceCompanyGenetecComponent } from './company-settings/genetec/fastpace.company.genetec.component';
import { FastPaceCompanyNet2Component } from './company-settings/net2/fastpace.company.net2.component';
import { FastPaceCompanyAEOSComponent } from './company-settings/aeos/fastpace.company.aeos.component';
import { FastPaceCompanyAC2000Component } from './company-settings/ac2000/fastpace.company.ac2000.component';
import { RouterModule } from '@angular/router';
import { FastPaceCompanyBrivoComponent } from './company-settings/brivo/fastpace.company.brivo.component';
import { FastPaceCompanyOpenPathComponent } from './company-settings/openpath/fastpace.company.openpath.component';
import { FastPaceCompanyGeneaComponent } from './company-settings/genea/fastpace.company.genea.component';
import { FastPaceCompanyGallagherComponent } from './company-settings/gallagher/fastpace.company.gallagher.component';
import { FastPaceCompanyAMAGComponent } from './company-settings/amag/fastpace.company.amag.component';
import { FastPaceCompanyGalaxyComponent } from './company-settings/galaxy/fastpace.company.galaxy.component';

const components = [
  FastPaceCompanyDetailsComponent,
  FastPaceCompanyOnGuardComponent,
  FastPaceCompanyCCureComponent,
  FastPaceCompanyAC2000Component,
  FastPaceCompanyAEOSComponent,
  FastPaceCompanyNet2Component,
  FastPaceCompanyGenetecComponent,
  FastPaceCompanyNetboxComponent,
  FastPaceCompanyBrivoComponent,
  FastPaceCompanyOpenPathComponent,
  FastPaceCompanyGeneaComponent,
  FastPaceCompanyGallagherComponent,
  FastPaceCompanyAMAGComponent,
  FastPaceCompanyGalaxyComponent
];

@NgModule({
  imports: [SharedModule, RouterModule],
  declarations: [components],
  exports: [components],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class FastPaceSharedModule {}
