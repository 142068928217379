import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FastpaceComponent } from '~/pages/fastpace/fastpace.component';
import { FastPaceSharedModule } from '~/shared/fastpace/fastpace.shared.module';
import { RightGuard } from '~/shared/guards/right.guard';
import { SharedModule } from '~/shared/shared.module';
import { UserRights } from '../../../../../shared/models';
import { FastPaceCompanyDetailsPageComponent } from './companies/fastpace-company-details-page/fastpace.company.details.page.component';
import { FastPaceCompaniesComponent } from './companies/fastpace.companies.component';
import { ExtenderDetailsPageComponent } from './extenders/extender-details-page/extender.details.page.component';
import { ExtenderSeqLinkComponent } from './extenders/extender-seq-link/extender.seq.link.component';
import { ExtendersComponent } from './extenders/extenders.component';
import { NewExtenderComponent } from './extenders/new-extender/new.extender.component';
import { SetupFastPaceCompanyComponent } from './companies/setup-fastpace/company-setup-fastpace.component';
import { SelectExtenderComponent } from './companies/setup-fastpace/select-extender/select-extender.component';
import { PaginationDotsComponent } from './companies/setup-fastpace/pagination-dots/pagination-dots.component';
import { SetupFooterComponent } from './companies/setup-fastpace/setup-footer.component';
import { SelectTokenGroupComponent } from './companies/setup-fastpace/setup-tokens/select-token-group/select-token-group.component';
import { SetTokenRangeComponent } from './companies/setup-fastpace/setup-tokens/token-range/set-token-range.component';
import { GenerateTokensComponent } from './companies/setup-fastpace/setup-tokens/generate-tokens/generate-tokens.component';
import { CustomFieldAccessLevelsComponent } from './companies/setup-fastpace/customfields-accesslevels/customfields-accesslevels.component';
import { CopySettingsComponent } from './companies/setup-fastpace/copy-settings/fastpace-copy-settings.component';
import { FastPaceSetupReviewComponent } from './companies/setup-fastpace/review/fastpace-setup-review.component';
import { FastPaceSetupSubmitComponent } from './companies/setup-fastpace/submit/fastpace-setup-submit.component';
import { AeosSetupComponent } from './companies/setup-fastpace/extender-setup/aeos/aeos-setup.component';
import { AC2000SetupComponent } from './companies/setup-fastpace/extender-setup/ac2000/ac2000-setup.component';
import { CCureSetupComponent } from './companies/setup-fastpace/extender-setup/ccure/ccure-setup.component';
import { GenetecSetupComponent } from './companies/setup-fastpace/extender-setup/genetec/genetec-setup.component';
import { OnGuardSetupComponent } from './companies/setup-fastpace/extender-setup/onguard/onguard-setup.component';
import { NetboxSetupComponent } from './companies/setup-fastpace/extender-setup/netbox/netbox-setup.component';
import { NewExtenderClientComponent } from './extenders/new-extender/client/new-extender.client.component';
import { NewExtenderBrivoComponent } from './extenders/new-extender/brivo/new-extender.brivo.component';
import { ExtenderDetailsClientComponent } from './extenders/extender-details-page/client/extender.details.client.component';
import { ExtenderDetailsBrivoComponent } from './extenders/extender-details-page/brivo/extender.details.brivo.component';
import { BrivoSetupComponent } from './companies/setup-fastpace/extender-setup/brivo/brivo-setup.component';
import { NewExtenderOpenPathComponent } from './extenders/new-extender/openpath/new-extender.openpath.component';
import { ExtenderDetailsOpenPathComponent } from './extenders/extender-details-page/openpath/extender.details.openpath.component';
import { OpenPathSetupComponent } from './companies/setup-fastpace/extender-setup/openpath/openpath-setup.component';
import { ExtenderDetailsGeneaComponent } from './extenders/extender-details-page/genea/extender.details.genea.component';
import { NewExtenderGeneaComponent } from './extenders/new-extender/genea/new-extender.genea.component';
import { GeneaSetupComponent } from './companies/setup-fastpace/extender-setup/genea/genea-setup.component';
import { GallagherSetupComponent } from './companies/setup-fastpace/extender-setup/gallagher/gallagher-setup.component';
import { AMAGSetupComponent } from './companies/setup-fastpace/extender-setup/amag/amag-setup.component';
import { ProovrDetailsPageComponent } from './extenders/extender-details-page/proovr/proovr.details.page.component';
import { GalaxySetupComponent } from './companies/setup-fastpace/extender-setup/galaxy/galaxy-setup.component';

@NgModule({
  declarations: [
    FastpaceComponent,
    ExtendersComponent,
    FastPaceCompaniesComponent,
    ExtenderDetailsPageComponent,
    ExtenderSeqLinkComponent,
    FastPaceCompanyDetailsPageComponent,
    NewExtenderComponent,
    SetupFastPaceCompanyComponent,
    SelectExtenderComponent,
    PaginationDotsComponent,
    SetupFooterComponent,
    GenerateTokensComponent,
    SelectTokenGroupComponent,
    SetTokenRangeComponent,
    CustomFieldAccessLevelsComponent,
    CopySettingsComponent,
    AC2000SetupComponent,
    AeosSetupComponent,
    CCureSetupComponent,
    GallagherSetupComponent,
    GenetecSetupComponent,
    NetboxSetupComponent,
    OnGuardSetupComponent,
    FastPaceSetupReviewComponent,
    FastPaceSetupSubmitComponent,
    NewExtenderClientComponent,
    NewExtenderBrivoComponent,
    NewExtenderOpenPathComponent,
    NewExtenderGeneaComponent,
    ExtenderDetailsClientComponent,
    ExtenderDetailsBrivoComponent,
    ExtenderDetailsOpenPathComponent,
    ExtenderDetailsGeneaComponent,
    BrivoSetupComponent,
    OpenPathSetupComponent,
    GeneaSetupComponent,
    AMAGSetupComponent,
    ProovrDetailsPageComponent,
    GalaxySetupComponent
  ],
  imports: [
    SharedModule,
    FastPaceSharedModule,
    RouterModule.forChild([
      {
        path: 'fastpace',
        component: FastpaceComponent,
        canActivate: [RightGuard],
        data: {
          right: UserRights.TabFastPace,
          title: 'Access control',
        },
        children: [
          {
            path: 'extenders',
            component: ExtendersComponent,
          },
          {
            path: 'extenders/new',
            component: NewExtenderComponent,
          },
          {
            path: 'extenders/:id',
            component: ExtenderDetailsPageComponent,
          },
          {
            path: 'companies',
            component: FastPaceCompaniesComponent,
          },
          {
            path: 'companies/:id',
            component: FastPaceCompanyDetailsPageComponent,
          },
          {
            path: 'companies/:id/setup',
            component: SetupFastPaceCompanyComponent,
          },
          {
            path: '',
            redirectTo: 'extenders',
            pathMatch: 'full',
          },
          {
            path: '**',
            redirectTo: 'extenders',
          },
        ],
      },
    ]),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class FastPaceModule {}
