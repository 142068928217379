import {
  IBaseCreateOrUpdateExtenderRequestV1,
  ICreateExtenderResponseV1,
  IExtenderPaginatedResponse,
  IExtenderResponseV1,
  IExtenderTokenGroupResponseV1,
  IExtenderTypeResponseV1,
} from '../store/extender/extender.models';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IQueryablePaginationFilter } from '~/models/filters';
import { ICCureExtenderConfig } from '~/store/extender/ccure/extender.ccure.models';
import { ExtenderServerCCureClient } from '@proxyclick/extender-server-ccure-client';
import { ExtenderServerAC2000Client } from '@proxyclick/extender-server-ac2000-client';
import { ExtenderServerAeosClient } from '@proxyclick/extender-server-aeos-client';
import { ExtenderServerGenetecClient } from '@proxyclick/extender-server-genetec-client';
import { ExtenderServerNet2Client } from '@proxyclick/extender-server-net2-client';
import { ExtenderServerNetboxClient } from '@proxyclick/extender-server-netbox-client';
import { ExtenderServerOnGuardClient } from '@proxyclick/extender-server-onguard-client';
import { ExtenderServerBrivoClient } from '@proxyclick/extender-server-brivo-client';
import { ExtenderServerGeneaClient } from '@proxyclick/extender-server-genea-client';
import { ExtenderServerOpenPathClient } from '@proxyclick/extender-server-openpath-client';
import { ExtenderServerGallagherClient } from '@proxyclick/extender-server-gallagher-client';
import { ExtenderServerAmagClient } from '@proxyclick/extender-server-amag-client';
import { ExtenderServerGalaxyClient } from '@proxyclick/extender-server-galaxy-client';
import * as coreHttp from '@azure/core-http';
import { JWTService } from './jwt.service';

export class AdminCredentials implements coreHttp.ServiceClientCredentials {
  constructor(private jwtService: JWTService) {}

  async signRequest(webResource: coreHttp.WebResourceLike): Promise<coreHttp.WebResourceLike> {
    webResource.headers.set('Authorization', `Bearer ${this.jwtService.getJWT()}`);
    return Promise.resolve(webResource);
  }
}

@Injectable()
export class ExtenderService {
  constructor(private http: HttpClient, private jwtService: JWTService) {
    var adminCredentials = new AdminCredentials(jwtService);
    var host = 'extender-api';
    this.ccure = new ExtenderServerCCureClient(adminCredentials, host, {
      noRetryPolicy: true,
    });
    this.ac2000 = new ExtenderServerAC2000Client(adminCredentials, host, {
      noRetryPolicy: true,
    });
    this.aeos = new ExtenderServerAeosClient(adminCredentials, host, {
      noRetryPolicy: true,
    });
    this.genetec = new ExtenderServerGenetecClient(adminCredentials, host, {
      noRetryPolicy: true,
    });
    this.net2 = new ExtenderServerNet2Client(adminCredentials, host, {
      noRetryPolicy: true,
    });
    this.netbox = new ExtenderServerNetboxClient(adminCredentials, host, {
      noRetryPolicy: true,
    });
    this.onguard = new ExtenderServerOnGuardClient(adminCredentials, host, {
      noRetryPolicy: true,
    });
    this.openpath = new ExtenderServerOpenPathClient(adminCredentials, host, {
      noRetryPolicy: true,
    });
    this.brivo = new ExtenderServerBrivoClient(adminCredentials, host, {
      noRetryPolicy: true,
    });
    this.genea = new ExtenderServerGeneaClient(adminCredentials, host, {
      noRetryPolicy: true,
    });
    this.gallagher = new ExtenderServerGallagherClient(adminCredentials, host, {
      noRetryPolicy: true,
    });
    this.amag = new ExtenderServerAmagClient(adminCredentials, host, {
      noRetryPolicy: true,
    });
    this.galaxy = new ExtenderServerGalaxyClient(adminCredentials, host, {
      noRetryPolicy: true,
    });
  }

  ccure: ExtenderServerCCureClient;
  ac2000: ExtenderServerAC2000Client;
  aeos: ExtenderServerAeosClient;
  genetec: ExtenderServerGenetecClient;
  net2: ExtenderServerNet2Client;
  netbox: ExtenderServerNetboxClient;
  onguard: ExtenderServerOnGuardClient;
  brivo: ExtenderServerBrivoClient;
  genea: ExtenderServerGeneaClient;
  openpath: ExtenderServerOpenPathClient;
  gallagher: ExtenderServerGallagherClient;
  amag: ExtenderServerAmagClient;
  galaxy: ExtenderServerGalaxyClient;

  public listExtenders(
    filter: IQueryablePaginationFilter
  ): Observable<IExtenderPaginatedResponse<IExtenderResponseV1>> {
    let url = `/extender-api/v1/extenders?page=${filter.page}&pageSize=${filter.pageSize}`;
    if (filter.q) {
      url += `&search=${filter.q}`;
    }
    return this.http.get<IExtenderPaginatedResponse<IExtenderResponseV1>>(url);
  }

  public listExtenderTypes(): Observable<IExtenderTypeResponseV1[]> {
    return this.http.get<IExtenderTypeResponseV1[]>(`/extender-api/v1/extenders/types`);
  }

  public findExtender(extenderId: string): Observable<IExtenderResponseV1 | null> {
    return this.http.get<IExtenderResponseV1>(`/extender-api/v1/extenders/${extenderId}`).pipe(this.catch404s());
  }

  public async updateExtender(extenderId: string, request: IBaseCreateOrUpdateExtenderRequestV1): Promise<void> {
    await this.http.put(`/extender-api/v1/extenders/${extenderId}/${request.type}`, request).toPromise();
  }

  public async refreshClientSettings(clientId: string): Promise<void> {
    await this.http.post(`/extender-api/v1/clients/${clientId}/refresh-settings`, {}).toPromise();
  }

  public async deleteExtender(extenderId: string): Promise<void> {
    await this.http.delete(`/extender-api/v1/extenders/${extenderId}`).toPromise();
  }

  public createExtender(request: IBaseCreateOrUpdateExtenderRequestV1): Observable<ICreateExtenderResponseV1> {
    let url = `/extender-api/v1/extenders/${request.type}`;
    return this.http.post<ICreateExtenderResponseV1>(url, request);
  }

  public listTokenGroups(
    filter: IQueryablePaginationFilter,
    extenderId: string | null = null
  ): Observable<IExtenderPaginatedResponse<IExtenderTokenGroupResponseV1>> {
    let url = `/extender-api/v1/token_groups?page=${filter.page}&pageSize=${filter.pageSize}`;
    if (filter.q) {
      url += `&search=${filter.q}`;
    }
    if (extenderId) {
      url += `&extenderId=${extenderId}`;
    }
    return this.http.get<IExtenderPaginatedResponse<IExtenderTokenGroupResponseV1>>(url);
  }

  public findTokenGroup(tokenGroupId: string): Observable<IExtenderTokenGroupResponseV1> {
    return this.http.get<IExtenderTokenGroupResponseV1>(`/extender-api/v1/token_groups/${tokenGroupId}`);
  }

  public getCCureExtenderConfig(extenderId: string): Observable<ICCureExtenderConfig> {
    return this.http.get<ICCureExtenderConfig>(`/extender-api/v1/extenders/${extenderId}/ccure/config`);
  }

  private catch404s = () => {
    return catchError(error => {
      if (error && error.status === 404) {
        return of(null);
      }
      throw error;
    });
  };
}
