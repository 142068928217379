import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as coreHttp from '@azure/core-http';
import {
  CompaniesListResponse,
  ControllersCompaniesAC2000CompanySettingsResponseV1,
  ControllersCompaniesAC2000SetupCompanyRequestV1,
  ControllersCompaniesAC2000UpdateCompanySettingsRequestV1,
  ControllersCompaniesAeosCompanySettingsResponseV1,
  ControllersCompaniesAeosSetupCompanyRequestV1,
  ControllersCompaniesAeosUpdateCompanySettingsRequestV1,
  ControllersCompaniesAmagCompanySettingsResponseV1,
  ControllersCompaniesAmagSetupCompanyRequestV1,
  ControllersCompaniesAmagUpdateCompanyRequestV1,
  ControllersCompaniesBaseSetupCompanyRequestV1,
  ControllersCompaniesBrivoCompanySettingsResponseV1,
  ControllersCompaniesBrivoSetupCompanyRequestV1,
  ControllersCompaniesBrivoUpdateCompanyRequestV1,
  ControllersCompaniesCCureCompanySettingsResponseV1,
  ControllersCompaniesCCureSetupCompanyRequestV1,
  ControllersCompaniesCCureUpdateCompanyRequestV1,
  ControllersCompaniesFastPaceCompanyResponseV1,
  ControllersCompaniesGallagherCompanySettingsResponseV1,
  ControllersCompaniesGallagherSetupCompanyRequestV1,
  ControllersCompaniesGallagherUpdateCompanyRequestV1,
  ControllersCompaniesGeneaCompanySettingsResponseV1,
  ControllersCompaniesGeneaSetupCompanyRequestV1,
  ControllersCompaniesGeneaUpdateCompanyRequestV1,
  ControllersCompaniesGenetecCompanySettingsResponseV1,
  ControllersCompaniesGenetecSetupCompanyRequestV1,
  ControllersCompaniesGenetecUpdateCompanySettingsRequestV1,
  ControllersCompaniesGalaxyCompanySettingsResponseV1,
  ControllersCompaniesGalaxySetupCompanyRequestV1,
  ControllersCompaniesGalaxyUpdateCompanyRequestV1,
  ControllersCompaniesNet2CompanySettingsResponseV1,
  ControllersCompaniesNet2UpdateCompanySettingsRequestV1,
  ControllersCompaniesNetboxCompanySettingsResponseV1,
  ControllersCompaniesNetboxSetupCompanyRequestV1,
  ControllersCompaniesNetboxUpdateCompanySettingsRequestV1,
  ControllersCompaniesOnGuardCompanySettingsResponseV1,
  ControllersCompaniesOnGuardSetupCompanyRequestV1,
  ControllersCompaniesOnGuardUpdateCompanyRequestV1,
  ControllersCompaniesOpenPathCompanySettingsResponseV1,
  ControllersCompaniesOpenPathSetupCompanyRequestV1,
  ControllersCompaniesOpenPathUpdateCompanyRequestV1,
  ControllersCompaniesUpdateCompanyRequestV1,
  ControllersExtendersExtenderResponseV1,
  FastPaceClient,
} from '@proxyclick/fastpace-client';
import { IQueryablePaginationFilter } from '~/models/filters';
import { FastPaceSetupCompanyState } from '~/pages/fastpace/companies/setup-fastpace/fastpace-setup-company.state';
import { JWTService } from './jwt.service';

export class AdminCredentials implements coreHttp.ServiceClientCredentials {
  constructor(private jwtService: JWTService) {}

  async signRequest(webResource: coreHttp.WebResourceLike): Promise<coreHttp.WebResourceLike> {
    webResource.headers.set('Authorization', `Bearer ${this.jwtService.getJWT()}`);
    return Promise.resolve(webResource);
  }
}

@Injectable()
export class FastPaceService {
  client: FastPaceClient;

  constructor(private http: HttpClient, private jwtService: JWTService) {
    const adminCredentials = new AdminCredentials(jwtService);
    const host = 'fastpace-api';

    this.client = new FastPaceClient(adminCredentials, host, {
      noRetryPolicy: true,
    });
  }

  public getCompanies(filter: IQueryablePaginationFilter): Promise<CompaniesListResponse> {
    return this.client.companies.list({ search: filter.q, page: filter.page, itemsPerPage: filter.pageSize });
  }

  public getCompany(companyId: string): Promise<ControllersCompaniesFastPaceCompanyResponseV1 | null> {
    return this.client.companies.findById(companyId).then(this.handle404);
  }
  public getExtender(extenderId: string): Promise<ControllersExtendersExtenderResponseV1 | null> {
    return this.client.extenders.findById(extenderId).then(this.handle404);
  }

  public retryExtenderUpdates(extenderId: string, daysInPast: number) {
    return this.client.extenders.retryUpdates(extenderId, { daysInPast });
  }
  public retryExtenderUpdatesForCompany(companyId: string, daysInPast: number) {
    return this.client.companies.retryUpdates(companyId, { daysInPast });
  }
  public refreshCompaniesExtenderSettings(extenderId: string) {
    return this.client.extenders.refreshCompanySettings(extenderId);
  }
  public refreshCompanyExtenderSettings(companyId: string) {
    return this.client.companies.refreshSettings(companyId);
  }

  public async create(state: FastPaceSetupCompanyState): Promise<void> {
    const request: ControllersCompaniesBaseSetupCompanyRequestV1 = {
      companyId: state.company.id,
      extenderId: state.extender ? state.extender.extenderId : null,
      generateTokens: state.generateTokens,
      tokenGroupId:
        state.selectedTokenGroup !== undefined && state.generateTokens ? state.selectedTokenGroup.value : null,
      tokenGroupName: state.tokenGroupName !== undefined && state.generateTokens ? state.tokenGroupName : null,
      minimumTokenRange: state.generateTokens ? state.tokenRangeMinimum : null,
      maximumTokenRange: state.generateTokens ? state.tokenRangeMaximum : null,
      customFieldAccessLevel: state.accessLevelsCustomField !== undefined ? state.accessLevelsCustomField.id : null,
      copySettingsFromRandomExtenderCompany: state.copySettingsFromRandomExtenderCompany,
      copySettingsFromCompanyId:
        state.copySettingsFromCompany !== undefined ? state.copySettingsFromCompany.companyId : null,
      allowPhysicalCards: false,
    };
    if (state.extender) {
      switch (state.extender.type) {
        case 'ac2000':
          const ac2000Request: ControllersCompaniesAC2000SetupCompanyRequestV1 = {
            ...request,
            aC2000ConnectionId: state.ac2000Connection.connectionId,
            aC2000DeviceFilterIn: state.ac2000DeviceFilterIn.map(x => x.deviceAddress),
            aC2000DeviceFilterOut: state.ac2000DeviceFilterOut.map(x => x.deviceAddress),
          };
          await this.client.companies.aC2000Create(ac2000Request);
          break;
        case 'aeos':
          const aeosRequest: ControllersCompaniesAeosSetupCompanyRequestV1 = {
            ...request,
            aeosUseEmployeeType: state.aeosUseEmployeeType,
            aeosHostEmailAsContact: state.aeoshostEmailAsContact,
            aeosCustomFieldMappings: state.aeosCustomFieldFreeFieldMappings.map(v => {
              return { freeFieldId: v.freeField.id, customFieldId: v.customField.id };
            }),
            aeosUnitId: null,
            cardIdentifierTypeId: state.aeosCardIdentifierType?.id ?? null,
          };
          await this.client.companies.aeosCreate(aeosRequest);
          break;
        case 'ccure':
          const ccureRequest: ControllersCompaniesCCureSetupCompanyRequestV1 = {
            ...request,
            cCureCustomFieldMappings: state.ccureCustomFieldMappings.map(v => {
              return { customFieldId: v.customField.id, fieldName: v.fieldName };
            }),
            cCurePartitionId: state.ccurePartition != null ? state.ccurePartition.objectId : null,
            cCureUsePersonnelGroup: state.ccureUsePersonnelGroup,
            cCurePersonnelGroupId: state.ccurePersonnelGroup != null ? state.ccurePersonnelGroup.objectId : null,
            cCureCardChuidFormatId: state.ccureCardCHUIDFormat?.objectId ?? null,
            cCureFacilityCode: state.ccureFacilityCode,
          };
          await this.client.companies.cCureCreate(ccureRequest);
          break;
        case 'genetec':
          const genetecRequest: ControllersCompaniesGenetecSetupCompanyRequestV1 = {
            ...request,
            genetecPartitionId: state.genetecPartition != null ? state.genetecPartition.guid : null,
            genetecCardCredentialFormatId: state.genetecCardCredentialFormat?.formatId,
            genetecFacilityCode: state.genetecFacilityCode,
          };
          await this.client.companies.genetecCreate(genetecRequest);
          break;
        case 'netbox':
          const netboxRequest: ControllersCompaniesNetboxSetupCompanyRequestV1 = {
            ...request,
            netboxManageAccessLevels: state.netboxManageAccessLevels,
            netboxPartitionKey: state.netboxPartitionKey,
            netboxVisitorIdField: state.netboxVisitorIdField,
            netboxVisitorStatusField: state.netboxVisitorStatusField,
            netboxUseMultiPartitionAccessLevelGroups: state.netboxUseMultiPartitionAccessLevelGroups,
            netboxCardFormat: state.netboxCardFormatOption ? state.netboxCardFormatOption.value : null,
          };
          await this.client.companies.netboxCreate(netboxRequest);
          break;
        case 'net2':
          await this.client.companies.net2Create(request);
          break;
        case 'onguard':
          const onGuardRequest: ControllersCompaniesOnGuardSetupCompanyRequestV1 = {
            ...request,
            onGuardAccessLevelSegmentId:
              state.onGuardAccessLevelSegement != null ? state.onGuardAccessLevelSegement.id : null,
            onGuardCardholderSegmentId:
              state.onGuardCardHolderSegment != null ? state.onGuardCardHolderSegment.id : null,
            onGuardCustomFieldMappings: state.onGuardCustomFieldMappings.map(v => {
              return {
                customFieldId: v.customField.id,
                fieldType: v.fieldType.typeName,
                fieldName: v.fieldProperty.name,
              };
            }),
            cardBadgeTypeId: state.onGuardCardBadgeType ? state.onGuardCardBadgeType.id : null,
          };
          await this.client.companies.onGuardCreate(onGuardRequest);
          break;
        case 'brivo':
          const brivoRequest: ControllersCompaniesBrivoSetupCompanyRequestV1 = {
            ...request,
            qrCodeCredentialFormatId: state.brivoQrCodeCredentialFormat.id,
            cardCredentialFormatId: state.brivoCardCredentialFormat?.id,
            facilityCode: state.brivoFacilityCode,
            customFieldMappings: state.brivoCustomFieldMappings.map(v => {
              return { customFieldId: v.customField.id, brivoCustomFieldId: v.brivoCustomField.id };
            }),
          };
          await this.client.companies.brivoCreate(brivoRequest);
          break;
        case 'openpath':
          const openpathRequest: ControllersCompaniesOpenPathSetupCompanyRequestV1 = {
            ...request,
            organizationId: state.openPathOrganization.id,
            qrCodeCardFormatId: state.openPathQrCodeCardFormat.id,
            cardFormatId: state.openPathCardCardFormat ? state.openPathCardCardFormat.id : null,
            facilityCode: state.openPathFacilityCode,
          };
          await this.client.companies.openPathCreate(openpathRequest);
          break;
        case 'genea':
          const geneaRequest: ControllersCompaniesGeneaSetupCompanyRequestV1 = {
            ...request,
            locationUuid: state.geneaLocation.uuid,
            tenantUuid: state.geneaTenant ? state.geneaTenant.uuid : null,
            customerUuid: state.geneaLocation.customerUuid,
          };
          await this.client.companies.geneaCreate(geneaRequest);
          break;
        case 'gallagher':
          const gallagherRequest: ControllersCompaniesGallagherSetupCompanyRequestV1 = {
            ...request,
            gallagherCustomFieldMappings: state.gallagherCustomFieldMappings.map(v => {
              return { customFieldId: v.customField.id, fieldName: v.fieldName };
            }),
            gallagherDivisionId: state.gallagherDivision != null ? state.gallagherDivision.id : null,
            cCardTypeId: state.gallagherCardCardType?.id,
          };
          await this.client.companies.gallagherCreate(gallagherRequest);
          break;
        case 'amag':
          const amagRequest: ControllersCompaniesAmagSetupCompanyRequestV1 = {
            ...request,
            amagCustomFieldMappings: state.amagCustomFieldMappings.map(v => {
              return { customFieldId: v.customField.id, amagPersonalDataTitleId: v.amagPersonalDataTitle.id };
            }),
            amagCompanyId: state.amagCompany?.id,
            amagCustomerCode: state.amagCustomerCode,
            amagQrCodeCardFormatId: state.amagQrCodeCardFormatId,
            amagCardFormatId: state.amagPhysicalCardFormatId,
          };
          await this.client.companies.amagCreate(amagRequest);
          break;
        case 'galaxy':
            const galaxyRequest: ControllersCompaniesGalaxySetupCompanyRequestV1 = {
              ...request,
              cardId: state.galaxyCardCardFormat ? state.galaxyCardCardFormat.id : null,
            };
            await this.client.companies.galaxyCreate(galaxyRequest);
            break;
      }
    } else {
      await this.client.companies.create(request);
    }
  }

  setupCompany(extenderType: string | null, request: object) {
    if (extenderType) {
      return this.http.post(`/fastpace-api/v1/companies/${extenderType}`, request);
    } else {
      return this.http.post(`/fastpace-api/v1/companies`, request);
    }
  }

  getCompaniesForExtender(extenderId: string): Promise<ControllersCompaniesFastPaceCompanyResponseV1[]> {
    return this.client.extenders.listAllCompanies(extenderId);
  }

  updateCompanySettings(companyId: string, request: ControllersCompaniesUpdateCompanyRequestV1) {
    return this.client.companies.update(companyId, request);
  }

  getAC2000Settings(companyId: string): Promise<ControllersCompaniesAC2000CompanySettingsResponseV1> {
    return this.client.companies.aC2000FindById(companyId);
  }
  updateAC2000Settings(
    companyId: string,
    request: ControllersCompaniesAC2000UpdateCompanySettingsRequestV1
  ): Promise<void> {
    return this.client.companies.aC2000Update(companyId, request).then();
  }
  getAEOSSettings(companyId: string): Promise<ControllersCompaniesAeosCompanySettingsResponseV1> {
    return this.client.companies.aeosFindById(companyId);
  }
  updateAEOSSettings(
    companyId: string,
    request: ControllersCompaniesAeosUpdateCompanySettingsRequestV1
  ): Promise<void> {
    return this.client.companies.aeosUpdate(companyId, request).then();
  }
  getGenetecSettings(companyId: string): Promise<ControllersCompaniesGenetecCompanySettingsResponseV1> {
    return this.client.companies.genetecFindById(companyId);
  }
  updateGenetecSettings(
    companyId: string,
    request: ControllersCompaniesGenetecUpdateCompanySettingsRequestV1
  ): Promise<void> {
    return this.client.companies.genetecUpdate(companyId, request).then();
  }
  getNet2Settings(companyId: string): Promise<ControllersCompaniesNet2CompanySettingsResponseV1> {
    return this.client.companies.net2FindById(companyId);
  }
  updateNet2Settings(
    companyId: string,
    request: ControllersCompaniesNet2UpdateCompanySettingsRequestV1
  ): Promise<void> {
    return this.client.companies.net2Update(companyId, request).then();
  }
  getNetboxSettings(companyId: string): Promise<ControllersCompaniesNetboxCompanySettingsResponseV1> {
    return this.client.companies.netboxFindById(companyId);
  }
  updateNetboxSettings(
    companyId: string,
    request: ControllersCompaniesNetboxUpdateCompanySettingsRequestV1
  ): Promise<void> {
    return this.client.companies.netboxUpdate(companyId, request).then();
  }
  getCCureSettings(companyId: string): Promise<ControllersCompaniesCCureCompanySettingsResponseV1> {
    return this.client.companies.cCureFindById(companyId);
  }
  updateCCureSettings(companyId: string, request: ControllersCompaniesCCureUpdateCompanyRequestV1): Promise<void> {
    return this.client.companies.cCureUpdate(companyId, request).then();
  }
  getOnGuardSettings(companyId: string): Promise<ControllersCompaniesOnGuardCompanySettingsResponseV1> {
    return this.client.companies.onGuardFindById(companyId);
  }
  updateOnGuardSettings(companyId: string, request: ControllersCompaniesOnGuardUpdateCompanyRequestV1): Promise<void> {
    return this.client.companies.onGuardUpdate(companyId, request).then();
  }
  getBrivoSettings(companyId: string): Promise<ControllersCompaniesBrivoCompanySettingsResponseV1> {
    return this.client.companies.brivoFindById(companyId);
  }

  updateBrivoSettings(companyId: string, request: ControllersCompaniesBrivoUpdateCompanyRequestV1): Promise<void> {
    return this.client.companies.brivoUpdate(companyId, request).then();
  }

  getOpenPathSettings(companyId: string): Promise<ControllersCompaniesOpenPathCompanySettingsResponseV1> {
    return this.client.companies.openPathFindById(companyId);
  }

  updateOpenPathSettings(
    companyId: string,
    request: ControllersCompaniesOpenPathUpdateCompanyRequestV1
  ): Promise<void> {
    return this.client.companies.openPathUpdate(companyId, request).then();
  }

  getGeneaSettings(companyId: string): Promise<ControllersCompaniesGeneaCompanySettingsResponseV1> {
    return this.client.companies.geneaFindById(companyId);
  }

  updateGeneaSettings(companyId: string, request: ControllersCompaniesGeneaUpdateCompanyRequestV1): Promise<void> {
    return this.client.companies.geneaUpdate(companyId, request).then();
  }

  getGallagherSettings(companyId: string): Promise<ControllersCompaniesGallagherCompanySettingsResponseV1> {
    return this.client.companies.gallagherFindById(companyId);
  }

  updateGallagherSettings(
    companyId: string,
    request: ControllersCompaniesGallagherUpdateCompanyRequestV1
  ): Promise<void> {
    return this.client.companies.gallagherUpdate(companyId, request).then();
  }

  getAMAGSettings(companyId: string): Promise<ControllersCompaniesAmagCompanySettingsResponseV1> {
    return this.client.companies.amagFindById(companyId);
  }

  updateAMAgSettings(companyId: string, request: ControllersCompaniesAmagUpdateCompanyRequestV1): Promise<void> {
    return this.client.companies.amagUpdate(companyId, request).then();
  }

  getGalaxySettings(companyId: string): Promise<ControllersCompaniesGalaxyCompanySettingsResponseV1> {
    return this.client.companies.galaxyFindById(companyId);
  }

  updateGalaxySettings(companyId: string, request: ControllersCompaniesGalaxyUpdateCompanyRequestV1): Promise<void> {
    return this.client.companies.galaxyUpdate(companyId, request).then();
  }
  

  handle404(response: any) {
    if (response && response._response && response._response.status === 404) {
      return null;
    }
    return response;
  }
}
