import { Component, Input, OnInit } from '@angular/core';
import { CompaniesService } from '~/services/companies.service';
import { ExtenderService } from '~/services/extender.service';
import { FastPaceSetupCompanyState } from '../../fastpace-setup-company.state';
import { v4 as uuidv4 } from 'uuid';
import {
  PersonsCardFormatV1,
  PersonsPersonInfoV1,
  PersonsListCardFormatsResponseV1
} from '@proxyclick/extender-server-galaxy-client';

@Component({
  selector: 'app-fastpace-company-setup-extenders-galaxy',
  templateUrl: './galaxy-setup.component.html',
})
export class GalaxySetupComponent implements OnInit {
  @Input() setupState: FastPaceSetupCompanyState;

  constructor(private companyService: CompaniesService, private extenderService: ExtenderService) {}

  async ngOnInit(): Promise<void> {
    console.log("init",this.setupState);
    this.setupState.galaxyQrCodeCardFormat = { id: 0, name: 'GalaxyStandard' };
    const response: PersonsListCardFormatsResponseV1 = await this.extenderService.galaxy.persons.listCardFormats(this.setupState.extender.extenderId,{
      requestTracingId: uuidv4(),
    });
this.cardFormats = response.cardFormats?.filter(x => x.id !== null) || this.hardcodedCardFormats;
        this.setPersonOptions();
  }

  persons: PersonsPersonInfoV1[] = [];
  personOptions: PersonsPersonInfoV1[] = [];
  personOption: PersonsPersonInfoV1 | null = null;
  cardFormats: PersonsCardFormatV1[] = [];
  hardcodedCardFormats: PersonsCardFormatV1[] = [
    { id: 0, name: 'GalaxyStandard' },
    { id: 1, name: 'Barcode' },
    { id: 2, name: 'MagneticStripe' },
    { id: 3, name: 'Wiegand26Bit' },
    { id: 4, name: 'ABA' },
    { id: 5, name: 'GalaxyKeypad' },
    { id: 6, name: 'HIDCorp1000' },
    { id: 7, name: 'PIV75Bit' },
    { id: 8, name: 'BQT36Bit' },
    { id: 9, name: 'XceedID40Bit' },
    { id: 10, name: 'USGovernmentID' },
    { id: 11, name: 'HIDCorp1K48Bit' },
    { id: 12, name: 'Cypress37Bit' },
    { id: 13, name: 'HIDH1030437Bit' },
    { id: 14, name: 'HIDH1030237Bit' },
    { id: 15, name: 'SoftwareHouse37Bit' },
    { id: 16, name: 'BtFarpointeConektMobile' },
    { id: 17, name: 'BtHidMobileAccess' },
    { id: 18, name: 'BtStidMobileId' },
    { id: 19, name: 'BtAllegion' },
    { id: 20, name: 'BtBasIp' },
    { id: 21, name: 'BasIpQr' },
    { id: 22, name: 'Galaxy40Bit' },
    { id: 65535, name: 'None' }
  ];

  async personOptionChanged() {
    console.log("personOptionChanged",this.setupState);
    if (this.personOption && this.setupState.galaxyPerson !== this.personOption) {
      const response: PersonsListCardFormatsResponseV1 = await this.extenderService.galaxy.persons.listCardFormats(this.setupState.extender.extenderId,{
        requestTracingId: uuidv4(),
      });
      this.cardFormats = response.cardFormats || this.hardcodedCardFormats;
      if (this.cardFormats.length > 0) {
        this.setupState.galaxyQrCodeCardFormat = this.cardFormats[0];
      } else {
        this.setupState.galaxyQrCodeCardFormat = { id: 0, name: 'GalaxyStandard' };
      }
    }
  }

  setPersonOptions() {
    this.cardFormats = this.hardcodedCardFormats
    this.personOptions = this.persons;
    if (this.personOptions.length > 0) {
      this.personOption = this.personOptions[0];
    } else {
      this.personOption = null;
    }
  }

  canGoNext() {
    return true;
    //return this.setupState.galaxyPerson && this.setupState.galaxyQrCodeCardFormat;
  }
}
